import { createQueryKeys } from '@lukemorales/query-key-factory'
import type { inferQueryKeys } from '@lukemorales/query-key-factory'
import type { SETTINGS_KEYS } from '~/api/mutations/management'

export const management = createQueryKeys('management', {
  defaultCommission: null,
  settings: (keys: SETTINGS_KEYS[]) => [keys],
})

export type CountryKeys = inferQueryKeys<typeof management>
