import { createQueryKeys } from '@lukemorales/query-key-factory'
import type { inferQueryKeys } from '@lukemorales/query-key-factory'

export const commissions = createQueryKeys('commissions', {
  all: null,
  allPaginated: (query?: any) => [query],
  assignPartnerToDeal: null,
  removePartnerFromDeal: null,
})

export type CommissionsKeys = inferQueryKeys<typeof commissions>
