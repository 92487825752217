import { createQueryKeys } from '@lukemorales/query-key-factory'
import type { inferQueryKeys } from '@lukemorales/query-key-factory'
import type { ROLES_CODES } from '~/constants/roles'
import type { User } from '~/types/auth'

export const users = createQueryKeys('users', {
  me: null,
  all: null,
  allCRM: null,
  popups: null,
  total: null,
  allPaginated: (query: any) => [query],
  commissions: (userId: User['id']) => [userId],
  role: (role: ROLES_CODES) => [role],
  details: (userId: User['id']) => [userId],
  tracker: (id: string) => [id],
})

export type CountryKeys = inferQueryKeys<typeof users>
