import { mergeQueryKeys } from '@lukemorales/query-key-factory'
import type { inferQueryKeyStore } from '@lukemorales/query-key-factory'
import { partners } from './partners'
import { referrals } from './referrals'
import { dictionaries } from './dictionaries'
import { versions } from './versions'
import { users } from './users'
import { permissions } from './permissions'
import { roles } from './roles'
import { managers } from './managers'
import { agents } from './agents'
import { earnings } from './earnings'
import { management } from './management'
import { dashboard } from './dashboard'
import { invoices } from './invoices'
import { entities } from './entities'
import { commissions } from './commissions'
import { properties } from './properties'
import { payouts } from './payouts'

export const queries = mergeQueryKeys(
  dictionaries,
  dashboard,
  versions,
  users,
  partners,
  referrals,
  agents,
  permissions,
  roles,
  managers,
  earnings,
  management,
  invoices,
  entities,
  commissions,
  payouts,
  properties,
)

export type QueryKeys = inferQueryKeyStore<typeof queries>
