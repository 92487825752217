import { createQueryKeys } from '@lukemorales/query-key-factory'
import type { inferQueryKeys } from '@lukemorales/query-key-factory'
import { defaultQueryFilter } from '~/constants'
import type { Referral } from '~/types/auth'

export const referrals = createQueryKeys('referrals', {
  allPaginated: (query?: any) => [query || defaultQueryFilter],
  allInfinite: (page?: number) => [page],
  all: null,
  deals: (query?: any) => [query],
  commissions: (referralId: Referral['id']) => [referralId],
  details: (referralId: Referral['id']) => [referralId],
  timeline: (referralId: Referral['id']) => [referralId],
  notes: (referralId: Referral['id']) => [referralId],
})

export type CountryKeys = inferQueryKeys<typeof referrals>
