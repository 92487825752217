import { createQueryKeys } from '@lukemorales/query-key-factory'
import type { inferQueryKeys } from '@lukemorales/query-key-factory'
import type { DICTIONARY_KEYS, EXTERNAL_DICTIONARY_KEYS, PROPERTIES_DICTIONARY_KEYS } from '~/api/query/dictionaries'

export const dictionaries = createQueryKeys('dictionaries', {
  countries: null,
  languages: null,
  currencies: null,
  agreementStatuses: null,
  referralStatuses: null,
  callOutboundOutcomes: null,
  feeStatuses: null,
  entity: (id: DICTIONARY_KEYS | PROPERTIES_DICTIONARY_KEYS | EXTERNAL_DICTIONARY_KEYS) => [id],
})

export type CountryKeys = inferQueryKeys<typeof dictionaries>
