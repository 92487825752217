import { createQueryKeys } from '@lukemorales/query-key-factory'
import type { inferQueryKeys } from '@lukemorales/query-key-factory'
import type { PropertyOffer } from '~/types/properties'

export const properties = createQueryKeys('properties', {
  offer: (hash: PropertyOffer['id']) => [hash],
  all: (query?: any) => [query],
  map: (query?: any) => [query],
})

export type PropertiesKeys = inferQueryKeys<typeof properties>
