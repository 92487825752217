import { createQueryKeys } from '@lukemorales/query-key-factory'
import type { inferQueryKeys } from '@lukemorales/query-key-factory'
import { defaultQueryFilter } from '~/constants'
import type { Partner } from '~/types/auth'

export const partners = createQueryKeys('partners', {
  all: null,
  allPaginated: (query?: any) => [query || defaultQueryFilter],
  timeline: (partnerId: Partner['id']) => [partnerId],
})

export type CountryKeys = inferQueryKeys<typeof partners>
